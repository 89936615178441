.headerDiv{
    position: sticky;
    top: 0px;
    z-index: 3;
    height: 10svh;
    background-color: aliceblue;
    border-bottom: 2px #BDAC7D solid; 
}

.headerDiv h2{
    align-self: center;
    color: #434341;
    cursor: pointer;
}

.headerDiv img{
    cursor: pointer;
    object-fit: contain;
}