.sectionTitles{
    color: #434341;
    border-left: #BDAC7D 3px solid;
    padding-left: 10px;
}

.iconsGeneral{
    width: 30px !important;
    object-fit: contain;
}

.infoHolder{
    font-weight: 600;
    span{
        color: #BDAC7D;
    }
    p{
        margin-bottom: 0px;
    }
}

.pHolder{
    p{
     border-left: 2px #BDAC7D solid;
     padding-left: 10px;
    }
}


/* massage section*/

.massage{
    h2{
        margin-bottom: 8%;
    }
    a{
        color: #434341;
        font-size: 18px;
        margin-bottom: 16px;
    }
}

/* gym && workout section*/

.gym, .workoutPlatform{
    h2{
        margin-bottom: 8%;
    }
}