.sectionTitles{
    color: #434341;
    border-left: #BDAC7D 3px solid;
    padding-left: 10px;
}


.iconsGeneral{
    width: 30px !important;
    object-fit: contain;
}

.infoHolder{
    font-weight: 600;
    span{
        color: #BDAC7D;
    }
    p{
        margin-bottom: 0px;
    }
}

.allInclText{
    color: #BDAC7D;
    margin-bottom: 0px;
    align-self: end;
}

.epirus, .olympia, .beachBar{
    border-bottom: 8px #BDAC7D solid;
}

