.eventsImg{
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover; 
}

.imgHolder{
    height: 25vh;
}

.myText{
    text-align: start;
    padding-inline: 7%;
}