.card{
    position: relative;
    height: 30vh;
    background-color: white;
    border: none;
    cursor: pointer;
}

.card h3{
    color: white;
        z-index: 2;
}

.card img{
    position: absolute;
    top: 50%;
    transform: translate(-50%,-50%);
    left: 50%;
    width: 100%;
    height: 90%;
    object-fit: cover;
    filter: blur(0.8px) brightness(0.7);
}